import { Cell, Row } from "../../../../../components/Table";
import React, { useState } from "react";
import {
  getPrintableTime,
  getStandradDateTime,
  utcToLocalTime,
} from "../../../../../lib/datetime";
import ListingPage from "../../../../../containers/ListingPage";
import UserActivityFilters from "../Filters";
import { getMessage } from "../../../../../lib/translator";
import { USER_ACTIVITY_LOGS_ENTITIES } from "./userActivityConstants";
import { objectDiff } from "../../../../../utils/objectDiff";
import isEmpty from "lodash.isempty";
import emptyIcon from "./logs-empty.svg";

const tableProperties = (entityName) => {
  const actionText = (newValue, oldValue) => {
    if (isEmpty(newValue) && !isEmpty(oldValue)) {
      return "Deleted";
    } else if (!isEmpty(newValue) && isEmpty(oldValue)) {
      return "Created";
    } else if (!isEmpty(newValue) && !isEmpty(oldValue)) {
      const updatedFields = objectDiff(newValue, oldValue);
      const modifiedMessage = Object.keys(updatedFields).reduce(
        (modifiedStr, key, index) => {
          if (index > 0) {
            return modifiedStr.concat(", " + key);
          }
          return modifiedStr.concat(key);
        },
        ""
      );
      return modifiedMessage.concat(" Modified");
    }
    return "";
  };
  const getTableHeader = (entityName) => {
    const entity = USER_ACTIVITY_LOGS_ENTITIES.find(
      (currentEntity) => currentEntity.value === entityName
    );
    const tableHeaders = [
      "Select Entity",
      entity?.tableNameHeader ? ` ${entityName} Name` : false,
      "User",
      "Date",
      "Action",
    ].filter(Boolean);
    tableHeaders.push("");
    return tableHeaders;
  };
  return {
    headers: getTableHeader(entityName),
    row: (props) => {
      const {
        entityType,
        id,
        newValue,
        oldValue,
        user,
        createdAt,
        entityId,
        UserName,
        actionTime,
      } = props;

      return (
        <Row key={id}>
          <Cell>{entityType}</Cell>
          {(newValue?.name || oldValue?.name) && (
            <Cell>{newValue?.name || oldValue?.name}</Cell>
          )}
          <Cell>{user?.name || UserName}</Cell>
          <Cell>
            {createdAt
              ? getPrintableTime(getStandradDateTime(utcToLocalTime, createdAt))
              : actionTime
              ? getPrintableTime(
                  getStandradDateTime(utcToLocalTime, actionTime)
                )
              : null}
          </Cell>
          <Cell>
            {entityType} ID: <span className="bold">{entityId}</span>{" "}
            {entityType}
            {" -> "}
            {actionText(newValue, oldValue)
              .split(" ")
              .slice(0, -1)
              .join(" ")}{" "}
            <span className="bold">
              {actionText(newValue, oldValue).split(" ").slice(-1)[0]}
            </span>
          </Cell>
          <Cell>{""}</Cell>
        </Row>
      );
    },
  };
};

const emptyState = {
  icon: emptyIcon,
  message: getMessage("user.activity.logs.empty.message"),
};

function UserActivityLogsTable() {
  const [currentEntity] = useState(USER_ACTIVITY_LOGS_ENTITIES[0]);
  const [entityName, setEntityName] = useState(currentEntity.text);

  return (
    <>
      <ListingPage
        api={{
          url: currentEntity.url,
          transform: (response) => {
            if (!response.data || !response.data.history) return [];
            setEntityName(response.data.history?.[0]?.entityType);
            return response.data.history || [];
          },
        }}
        filters={{
          component: UserActivityFilters,
          showFiltersOnLoad: true,
        }}
        emptyState={emptyState}
        title={getMessage("menu.item.user-activity-logs")}
        className="user-activity-logs"
        tableProperties={tableProperties(entityName)}
      />
    </>
  );
}

export default UserActivityLogsTable;
