import React from "react";
import { BaseForm, Input, VALIDATION_TYPES } from "../../../components/Form";
import { Link, withRouter } from "react-router-dom";
import { getMessage } from "../../../lib/translator";
import { saveSession } from "../../../lib/auth";
import { set, get } from "../../../lib/storage";
import API from "../../../lib/api";
import { roleMapping } from "../../../lib/commonlyused";
import { GOOGLE_MAP_DEFAULT_KEY } from "../../../config/app";
import { getSession } from "../../../lib/auth";
import HidePassword from "../../../components/Form/icons/HidePassword";
import ShowPassword from "../../../components/Form/icons/ShowPassword";
import { GO_HOST } from "../../../config/app";

const version2auth = () => {
  if (GO_HOST.includes("wesellit.ph")) return true;
  return false;
};

class LoginForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.values = {
      username: "",
      password: "",
      remember: false,
    };
    if (this.props.enterprise) {
      this.state.values.organization = get("organization-name");
    }
    this.state.formError = "";
    this.state.showPassword = false;
    this.handleLogin = this.handleLogin.bind(this);
    this.autoFillAction = this.autoFillAction.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
  }

  handleLogin(formData) {
    this.api = new API({ url: "/account-service/auth" });
    this.api
      .post(formData, version2auth() && { "X-API-VERSION": 2 })
      .then((response) => {
        let token = response.data.user.accessToken;
        delete response.data.user.accessToken;
        saveSession({
          token: token,
          organization: response.data.organization,
          user: response.data.user,
        });
        this.setState({ submitting: false }, () => {
          // let configApi = new API({ url: '/config-service/config/backOffice' })
          const websiteConfigApi = new API({
            url: "/config-service/config/website",
          });
          const websitemeApi = new API({
            url: "/account-service/me",
          });
          Promise.all([websiteConfigApi.get(), websitemeApi.get()])
            .then(([websiteConfigResponse, websitemeApiResponse]) => {
              const key = websiteConfigResponse?.data?.website?.googleApisKey;
              saveSession({
                user: websitemeApiResponse?.data?.user,
              });
              set("googleMapAPIkey", key || GOOGLE_MAP_DEFAULT_KEY);
              this.handleRedirect(response);
            })
            .catch((error) => {
              console.error(error);
              if (error.code === 401) {
                throw error;
              } else {
                set("googleMapAPIkey", GOOGLE_MAP_DEFAULT_KEY);
                this.handleRedirect(response);
              }
            });
        });
      })
      .catch((e) => {
        console.error(e);
        this.setState({
          submitting: false,
          formError: e.message && e.message.split(":")[1],
        });
      });
  }
  handleRedirect(response) {
    const { history, location } = this.props;
    let role =
      response.data.user.designation &&
      response.data.user.designation.roles &&
      response.data.user.designation.roles[0]
        ? response.data.user.designation.roles[0]
        : {};
    let roleObject = roleMapping.find((mapping) => mapping.name === role.name);
    let roleRedirect = roleObject ? roleObject.redirect : null;
    const { organization } = getSession();
    roleRedirect = !organization?.industry ? "/onboarding" : roleRedirect;
    roleRedirect = roleRedirect || "/";
    if (location.state && location.state.from) {
      history.push(location.state.from.pathname);
    } else {
      history.push(roleRedirect);
    }
    this.props.enterprise &&
      set("organization-name", response.data.organization.name);
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    this.handleLogin(data);
  }

  autoFillAction(e) {
    this.setState({ autoFill: true });
  }

  componentDidMount() {
    window.addEventListener("animationstart", this.autoFillAction);
  }

  componentWillUnmount() {
    window.removeEventListener("animationstart", this.autoFillAction);
    this.api && this.api.cancel();
  }

  handleShowPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    const { enterprise } = this.props;
    const { showPassword } = this.state;
    const PasswordIcon = showPassword ? ShowPassword : HidePassword;

    return (
      <div>
        {this.state.formError && (
          <div className="form-error">{this.state.formError}</div>
        )}
        <Form>
          <div
            className={"username-wrapper" + (enterprise ? "" : " expand-width")}
          >
            <Input
              label={
                enterprise
                  ? getMessage("login.username.heading")
                  : getMessage("login.email.heading")
              }
              placeholder={
                enterprise
                  ? getMessage("login.username.placeholder")
                  : getMessage("login.email.placeholder")
              }
              name="username"
              type={enterprise ? "text" : "email"}
              required
              {...this.generateStateMappers({
                stateKeys: ["username"],
                validationType: enterprise
                  ? VALIDATION_TYPES.ONSUBMIT
                  : VALIDATION_TYPES.ONCHANGE,
              })}
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
                typeMismatch: enterprise
                  ? getMessage("input.invalidUsernameFormat")
                  : getMessage("input.invalidEmailFormat"),
              }}
            />
            {enterprise && <div className="at-the-rate">@</div>}
            {enterprise && (
              <Input
                label={getMessage("login.organisation.heading")}
                placeholder={getMessage("login.organisation.placeholder")}
                name="organisation"
                type="text"
                required
                {...this.generateStateMappers({
                  stateKeys: ["organization"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
                validationStrings={{
                  valueMissing: getMessage("input.requiredMessage"),
                }}
              />
            )}
          </div>
          <div className="password-wrp">
            <Input
              label={getMessage("login.password.heading")}
              placeholder={getMessage("login.password.placeholder")}
              name="password"
              type={showPassword ? "text" : "password"}
              required
              {...this.generateStateMappers({
                stateKeys: ["password"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
              }}
            />
            <div className="password-view" onClick={this.handleShowPassword}>
              <PasswordIcon />
            </div>
          </div>

          <div className="field cf">
            <input
              id="remember"
              type="checkbox"
              name="remember"
              checked={this.getState(["remember"])}
              onChange={(e) => {
                this.updateState(["remember"], e.target.checked);
              }}
            />
            <label htmlFor="remember" className="inline">
              {getMessage("login.remember")}
            </label>
            {!enterprise && (
              <Link to="/forgot-password" className="forgot-link">
                {getMessage("login.forgotPassword")}
              </Link>
            )}
          </div>
          <div className="form-buttons-container">
            {enterprise ? (
              <SubmitButton
                disabled={
                  !this.state.autoFill &&
                  (this.state.submitting ||
                    !this.state.values.username ||
                    !this.state.values.organization ||
                    !this.state.values.password)
                }
              >
                {getMessage("login.submitText")}
              </SubmitButton>
            ) : (
              <SubmitButton
                disabled={
                  !this.state.autoFill &&
                  (this.state.submitting ||
                    !this.state.values.username ||
                    !this.state.values.password)
                }
              >
                {getMessage("login.submitText")}
              </SubmitButton>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

export default withRouter(LoginForm);
