const data = {
  "menu.selectLanguage.heading": "Select language",
  "menu.changePassword.heading": "Change Password",
  "menu.item.operations": "Operations",
  "menu.item.dashboard": "Dashboard",
  "menu.item.stores": "Stores",
  "menu.item.customers": "Customers",
  "menu.item.orders": "Orders",
  "menu.item.orders-analytics": "Orders Analytics",
  "menu.item.reports": "Reports",
  "menu.item.customer-support": "Customer Support",
  "menu.item.trip-planner": "Trip Planner",
  "menu.item.logistics": "Logistics",
  "menu.item.catalogue": "Catalogue",
  "menu.item.categories": "Categories",
  "menu.item.brands": "Brands",
  "menu.item.products": "Products",
  "menu.item.tags": "Tags",
  "menu.item.substitution-groups": "Substitution Groups",
  "menu.item.marketing": "Marketing",
  "menu.item.coupons": "Coupons",
  "menu.item.blog": "Blog",
  "menu.item.rating-reviews": "Rating And Reviews",
  "menu.item.recipe": "Recipe",
  "menu.item.pages": "Pages",
  "menu.item.campaigns": "Campaigns",
  "menu.item.settings": "Settings",
  "menu.item.basic-information": "Basic information",
  "menu.item.contact-details": "Contact Details",
  "menu.item.themes": "Website",
  "menu.item.extensions": "Extensions",
  "menu.item.seo": "SEO",
  "menu.item.users": "Multi User Support",
  "menu.item.mobile-apps": "Mobile Apps",
  "menu.item.payment-configuration": "Online Payment",
  "menu.item.my-account": "My account",
  "menu.item.account-balance": "Account Balance",
  "menu.item.change-password": "Change Password",
  "menu.item.logout": "Logout",
  "menu.item.delivery-area": "Delivery Area",
  "menu.item.notifications": "Notifications",
  "menu.item.website-link": "Your online store",
  "menu.item.picking-queue": "Picking Queue",
  "menu.item.vehicles": "Vehicles",
  "menu.item.vehicle-planning": "Vehicle Planning",
  "menu.item.trips": "Trips",
  "menu.item.manage-runners": "Manage Runner Executives",
  "menu.item.designations": "Designations",
  "menu.item.leave-summary": "Leave Summary",
  "menu.item.attendance-summary": "Attendance Summary",
  "menu.item.hr": "HR",
  "menu.item.leave-management": "Leave Management",
  "menu.item.employees": "Employees",
  "menu.item.my-leaves": "My Leaves",
  "menu.item.rack-management": "Rack Mangement",
  "menu.item.disputes": "Disputes",
  "menu.item.returns": "Pending Returns",
  "menu.item.click-collect-orders": "Click & Collect Orders",
  "menu.notificationToday.empty": "There are no notification shown here",
  "menu.item.slots": "Slots",
  "menu.item.store-slot-config": "Store Slot Blocking",
  "menu.item.roles": "Roles",
  "menu.item.tawk": "Tawk.to Live Chat",
  "menu.item.Seo": "SEO",
  "menu.item.sellers": "Sellers",
  "menu.item.seller": "Sellers",
  "menu.item.user": "Users",

  "review.order.table.header.orderid": "OrderID",
  "review.order.table.header.rating": "Rating",
  "review.order.table.header.date": "Date",
  "review.order.table.header.customer": "Customer",
  "review.order.table.header.more": "More",
  "review.all.product.table.header.item": "Item",
  "review.individual.product.table.header.average.rating": "Average Ratings",

  "menu.item.metadata": "Custom fields",
  "menu.item.analytics": "Analytics",
  "menu.item.instore": "Instore Processing",
  "menu.item.pickers": "Manage Pickers",
  "menu.item.logistics-configuration": "Logistics",
  "menu.item.user-hover": "user hover",
  "menu.item.offers": "Offers",
  "menu.item.calls": "Calls",
  "menu.item.web-banners": "Banners",
  "menu.item.store-configuration": "Stock Overrides",
  "menu.item.search-configuration": "Search Overrides",
  "menu.item.communication-configuration": "Communication",
  "menu.item.customer-tags": "Customer Tags",
  "menu.item.capacity-planning": "Capacity Planning",
  "menu.item.bulk-coupons": "Bulk Coupons",
  "menu.item.slot-charges": "Slot Charges",
  "menu.item.order-config": "Order Config",
  "menu.item.integration-settings": "Integration Settings",
  "menu.item.order-package-config": "Packaging",
  "menu.item.courier-support": "Shipping",
  "menu.item.referral-management": "Referral Management",
  "menu.item.google-merchant-center": "Google Merchant Center",
  "menu.item.product-variant-support": "Product Variant",
  "menu.item.product-restrictions": "Product Restrictions",
  "menu.item.master-catalogue": "Master Catalogue",
  "menu.item.seller-catalogue": "Seller Catalogue",
  "menu.item.user-activity-logs": "User Activity Logs",
  "user.activity.logs.empty.message": "No logs available for this entity",
};
export default data;
