import React from "react";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import { Helmet } from "react-helmet";
import UserActivityLogsTable from "./Table";

export default function UserActivityLogs(props) {
  return (
    <AuthenticatedPage
      className="user-activity-logs"
      menu={props.menu}
      notShowHelmet={false}
    >
      <Helmet title="Zopping - User Activity Logging" />
      <UserActivityLogsTable />
    </AuthenticatedPage>
  );
}
