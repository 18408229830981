import React, { Component } from "react";
import { TABLE_ACTIONS } from "../../../containers/ListingPage";
import ListingPageWithRoutes from "../../../containers/ListingPage/listingRouter";
import { Row, Cell } from "../../../components/Table";
import Image from "../../../components/Image";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import CategoryForm from "./Form";
import { getMessage } from "../../../lib/translator";
import { Link } from "react-router-dom";
import CategoryFilters from "./Filters";

import emptyIcon from "./categories-empty.svg";
import helpCard1Icon from "./helpcard1.svg";
import helpCard2Icon from "./helpcard2.svg";
import helpCard3Icon from "./helpcard3.svg";
import tableActive from "./table-active.svg";
import treeViewInactive from "./tree-view-inactive.svg";

import "./style.css";
import { Popup } from "../../../components/Popup";
import CategoryUpload from "./CategoryUpload";

const emptyState = {
  icon: emptyIcon,
  message: getMessage("category.helpItems.message"),
  submessage: getMessage("category.helpItems.submessage"),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD);
      }}
    >
      + {getMessage("category.add.text")}
    </button>
  ),
};

const tableProperties = () => {
  return {
    headers: [
      getMessage("category.header.image"),
      getMessage("category.header.name"),
      getMessage("category.header.parentCategory"),
      getMessage("category.header.productCount"),
      getMessage("category.header.status"),
      getMessage("category.header.actions"),
    ],
    row: ({
      id,
      name,
      parentCategory,
      productsCount,
      status,
      image,
      onAction,
      slug,
    }) => (
      <Row>
        <Cell>
          <Image size="sm" src={image} bordered />
        </Cell>
        <Cell>
          <span
            className="category-name"
            onClick={() => {
              onAction(TABLE_ACTIONS.EDIT, { id });
            }}
          >
            {name}
          </span>
        </Cell>
        <Cell className="parent-category">
          <span className="text-muted">
            <span className="category-header-mobileview">{`${getMessage(
              "category.header.parentCategory"
            )}: `}</span>
            <small className="text-category-name">
              {parentCategory ? parentCategory.name : null}
            </small>
          </span>
        </Cell>
        <Cell className="product-count text-right">
          <span className="text-muted">
            <span className="category-productcount-mobileview">{`${getMessage(
              "category.header.productCount"
            )}: `}</span>
            <small className="text-product-count">{`${productsCount}`}</small>
          </span>
        </Cell>
        <Cell className="category-status">
          <small className="text-muted">{status}</small>
        </Cell>
        <Cell className="category-actions">
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.EDIT, { id });
              }}
            >
              {getMessage("category.action.edit")}
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.DELETE, { id });
              }}
            >
              {getMessage("category.action.delete")}
            </DropDownItem>
            <DropDownItem>
              <Link to={`/catalogue/products?slugCategory=${slug}`}>
                {" "}
                {getMessage("category.action.seeProducts")}
              </Link>
            </DropDownItem>
          </DropDown>
        </Cell>
      </Row>
    ),
  };
};
const helpItems = {
  title: getMessage("category.helpItems.title"),
  instructions: [
    {
      icon: helpCard1Icon,
      title: getMessage("category.helpItems.ppo.title"),
      description: getMessage("category.helpItems.ppo.description"),
    },
    {
      icon: helpCard2Icon,
      title: getMessage("category.helpItems.poc.title"),
      description: getMessage("category.helpItems.poc.description"),
    },
    {
      icon: helpCard3Icon,
      title: getMessage("category.helpItems.seo.title"),
      description: getMessage("category.helpItems.seo.description"),
    },
  ],
};

export default class Categories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mountListingPage: true,
      showPopup: false,
    };
  }

  refreshingData = () => {
    /**
     * Todo: To figure out a better way to update more than one rows of Listing page without remounting
     */
    this.setState({ mountListingPage: false });
    this.setState({ mountListingPage: true });
  };

  handleModalState = () => this.setState({ showPopup: !this.state.showPopup });

  render() {
    let props = this.props;
    const { mountListingPage } = this.state;
    if (mountListingPage)
      return (
        <ListingPageWithRoutes
          menu={props.menu}
          className="categories-page"
          addHeading={getMessage("category.form.add.heading")}
          editHeading={getMessage("category.form.edit.heading")}
          title={getMessage("category.heading")}
          api={{
            url: "/catalogue-service/category",
            transform: (response) => response.data.category,
            afterSubmit: () => {
              this.refreshingData();
            },
          }}
          showLanguageDropDown
          emptyState={emptyState}
          helpItems={helpItems}
          headerActions={({ onAction }) => (
            <div className="category-header">
              <div className="image-wrapper">
                <img
                  src={tableActive}
                  alt="table view"
                  title={getMessage("category.icons.table-title")}
                />
                <Link to="/catalogue/categories/tree-view">
                  <img
                    src={treeViewInactive}
                    alt="tree view"
                    title={getMessage("category.icons.tree-title")}
                  />
                </Link>
              </div>

              <div className="text-center">
                <Popup
                  heading={getMessage("product.add.bulk.text")}
                  show={this.state.showPopup}
                  close={this.handleModalState}
                  className="bulk-upload"
                >
                  <CategoryUpload />
                </Popup>
                <DropDown
                  className="bulk-upload-dropdown"
                  icon={
                    <button className="button primary add-seller-dropdown-button">
                      +{" "}
                      <span className="text">
                        {getMessage("category.upload.text")}
                      </span>
                    </button>
                  }
                >
                  <DropDownItem
                    onClick={() => {
                      onAction(TABLE_ACTIONS.ADD);
                    }}
                  >
                    <span className="text">
                      {getMessage("category.add.text")}
                    </span>
                  </DropDownItem>
                  <DropDownItem onClick={this.handleModalState}>
                    {getMessage("sellers.seller.form.add.bulk.text")}
                  </DropDownItem>
                </DropDown>
              </div>
            </div>
          )}
          form={{
            component: CategoryForm,
            transformSubmit: (formData) => {
              let result = Object.assign({}, formData);
              if (formData.parentCategory) {
                result.parentCategoryId = formData.parentCategory.id;
                delete result.parentCategory;
              }
              if (!formData.parentCategory) {
                delete result.parentCategoryId;
              }
              return result;
            },
            allowDelete: true,
          }}
          tableProperties={tableProperties(
            this.state && this.state.isMobileView
          )}
          filters={{
            component: CategoryFilters,
            transformSubmit: (formData) => {
              let data = Object.assign({}, formData);
              if (data.category) {
                data.name = data.category.name;
                delete data.category;
              }
              return data;
            },
          }}
        />
      );
    return null;
  }
}
