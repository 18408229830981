import isEmpty from "lodash.isempty";

export function objectDiff(obj1, obj2) {
  const diff = {};

  // Helper function to check for changes
  function checkChanges(key, value1, value2) {
    if (value1 !== value2) {
      if (isEmpty(value1) && isEmpty(value2)) {
        return;
      } else if (isPlainObject(value1) && isPlainObject(value2)) {
        const recursiveObj = objectDiff(value1, value2);
        if (isEmpty(recursiveObj)) return;
        diff[key] = { oldValue: value1, newValue: value2 };
      }
      if (value1 === undefined) {
        // Property was added
        diff[key] = { added: value2 };
      } else if (value2 === undefined) {
        // Property was removed
        diff[key] = { removed: value1 };
      } else {
        // Property was modified
        diff[key] = { oldValue: value1, newValue: value2 };
      }
    }
  }

  function isPlainObject(variable) {
    return Object.prototype.toString.call(variable) === "[object Object]";
  }

  // Check for properties in obj1
  for (const key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (key in obj2) {
        // If both objects have the property, check if it's changed
        checkChanges(key, obj1[key], obj2[key]);
      } else {
        // Property is removed in obj2
        diff[key] = { removed: obj1[key] };
      }
    }
  }

  // Check for properties in obj2 that are not in obj1 (added)
  for (const key in obj2) {
    if (obj2.hasOwnProperty(key) && !(key in obj1)) {
      diff[key] = { added: obj2[key] };
    }
  }

  return diff;
}
