/**
 * @member value of the entities are according to api backend params required
 */
export const USER_ACTIVITY_LOGS_ENTITIES = [
  {
    text: "Store",
    value: "Store",
    url: "/account-service/store-history",
    tableNameHeader: true,
  },
  {
    text: "Product",
    value: "Product",
    url: "/catalogue-service/product-history",
    tableNameHeader: true,
  },
  {
    text: "Category",
    value: "Category",
    url: "/catalogue-service/category-history",
    tableNameHeader: true,
  },
  {
    text: "Brand",
    value: "Brand",
    url: "/catalogue-service/brand-history",
    tableNameHeader: true,
  },
  {
    text: "Capacity",
    value: "Capacity",
    url: "/logistics-service/capacity-history",
    tableNameHeader: false,
  },
  {
    text: "Delivery area",
    value: "DeliveryArea",
    url: "/logistics-service/delivery-area-history",
    tableNameHeader: true,
  },
  {
    text: "Tag",
    value: "Tag",
    url: "/catalogue-service/tag-history",
    tableNameHeader: true,
  },
  {
    text: "Logistics settings",
    value: "logisticsSettings",
    url: "/config-service/logistics-settings-history",
    tableNameHeader: true,
  },
  {
    text: "Order configs",
    value: "OrderConfigs",
    url: "/config-service/order-configs-history",
    tableNameHeader: true,
  },
  {
    text: "payment method",
    value: "PaymentMethod",
    url: "/config-service/payment-method-history",
    tableNameHeader: false,
  },
  {
    text: "Slot blocking",
    value: "SlotBlocking",
    url: "/order-service/slot-blocking-history",
    tableNameHeader: false,
  },
  {
    text: "Slot",
    value: "Slot",
    url: "/order-service/slot-history",
    tableNameHeader: false,
  },
];
