import React, { Component } from "react";
import moment from "moment";
import { Route, Switch, Redirect } from "react-router-dom";

import SignUp from "../../pages/others/SignUp";
import Login from "../../pages/others/Login";
import ResetPassword from "../../pages/others/ResetPassword";
import Logout from "../../pages/others/Logout";
import NotDone from "../../pages/others/NotDone";
import MissingPage from "../../pages/others/MissingPage";
import Verify from "../../pages/others/Verify";
import AccountCreated from "../../pages/others/AccountCreated";
import PaymentResponse from "../../pages/others/PaymentResponse";

import Products from "../../pages/catalogue/Products/index";
import Categories from "../../pages/catalogue/Categories/index";
import CategoryTree from "../../pages/catalogue/Categories/TreeView";
import Brands from "../../pages/catalogue/Brands/index";
import CatalogueDashboard from "../../pages/catalogue/Dashboard";
import Tags from "../../pages/catalogue/Tags";
import SubstitutionGroups from "../../pages/catalogue/SubstitutionGroups";
import CouponAnalytics from "../../pages/marketing/analytics/coupon-analytics";

import CsDashboard from "../../pages/customer-support/Dashboard";
import CsCustomers from "../../pages/customer-support/Customers";
import CsOrders from "../../pages/customer-support/Orders";
// import CsCalls from '../../pages/customer-support/Calls'
// import Customers from '../../pages/operations/Customers'
import OrderDispatchForm from "../../pages/operations/Orders/OrderDispatchForm";
import OrdersAnalytics from "../../pages/analytics/orders-analytics";
import AnalyticsReport from "../../pages/analytics/reports";

import Orders from "../../pages/operations/Orders";
import OrderAddForm from "../../pages/operations/Orders/POS";
import OrderLog from "../../components/OrderLog";
import Pickers from "../../pages/operations/Pickers";
import PickersToday from "../../pages/operations/PickersToday";
import FileReturn from "../../pages/operations/Returns/Form/FileForReturn";
import UserShifts from "../../pages/hr/UserShifts";
import Sellers from "../../pages/sellers/Sellers";
import SellerUsers from "../../pages/sellers/Users";

import Vehicles from "../../pages/logistics/Vehicles";
import VehiclePlanner from "../../pages/logistics/VehiclePlanner";
import VehicleVendor from "../../pages/logistics/VehicleVendor";
import Trips from "../../pages/logistics/Trips";
import TripDetails from "../../pages/logistics/TripDetails";
import TripPlanner from "../../pages/logistics/TripPlanner";
import TripPlannerTable from "../../pages/logistics/TripPlanner/TableView";
import ManageRE from "../../pages/logistics/ManageRE's";
import TripPaymentsForm from "../../pages/logistics/Trips/TripPaymentsForm";
import LogisticsConfig from "../../pages/logistics/LogisticsConfig";

import Blogs from "../../pages/marketing/Blog/index";
import Recipe from "../../pages/marketing/Recipe/index";
import Coupons from "../../pages/marketing/Coupons/index";
import BulkCoupons from "../../pages/marketing/BulkCoupons";
import Pages from "../../pages/marketing/Pages/index";
import Campaigns from "../../pages/marketing/Campaigns";
import Offers from "../../pages/marketing/Offers";
import ManageBanners from "../../pages/marketing/BannerManagement";
import RatingAndReviews from "../../pages/marketing/ratingAndReviews";

import OperationsDashboard from "../../pages/operations/Dashboard";
import Stores from "../../pages/operations/Stores";
import OrderPickingQueue from "../../pages/operations/PickingQueue";
import Disputes from "../../pages/operations/Disputes";
import Returns from "../../pages/operations/Returns";
import FileDispute from "../../pages/operations/Disputes/FileDispute";
import StoreSlotConfigTable from "../../pages/stores/slots/StoreSlotConfigTable";
import OrderRegisterPayments from "../../pages/operations/Orders/RegisterPayments";
import EditPackedOrder from "../../pages/operations/Orders/EditPackedOrder/EditPackedOrder";
import CapacityPlanning from "../../pages/operations/CapacityPlanning";
import Today from "../../pages/operations/CapacityPlanning/Today";
import SlotCharges from "../../pages/operations/SlotCharges";
import PackOrder from "../../pages/operations/Orders/PackOrder";
import ClickCollectOrders from "../../pages/operations/ClickCollectOrders";

import Designations from "../../pages/hr/Designations";
import AttendanceSummary from "../../pages/hr/AttendanceSummary";

import BasicInformation from "../../pages/settings/BasicInformation/index";
import Analytics from "../../pages/settings/Analytics/index";
import SocialMedia from "../../pages/settings/SocialMedia/index";
import DeliveryArea from "../../pages/settings/DeliveryArea/index";
import Themes from "../../pages/settings/Themes/index";
import Extensions from "../../pages/settings/Extensions";
import StoreConfiguration from "../../pages/settings/StoreConfiguration";
import ExtensionDetailsForm from "../../pages/settings/Extensions/ExtensionDetailsForms";
import Users from "../../pages/settings/Users";
import MobileApps from "../../pages/settings/MobileApps";
import Notifications from "../../pages/settings/Notifications";
import PaymentConfiguration from "../../pages/settings/PaymentConfiguration";
import SEO from "../../pages/settings/SEO";
import RackManagement from "../../pages/settings/RackManagement";
import SearchConfiguration from "../../pages/settings/SearchConfigurations";
import CommunicationConfig from "../../pages/settings/Communication";
import CustomerTags from "../../pages/settings/CustomerTags";
import PickupConfiguration from "../../pages/settings/PickingConfiguration";
import WalletConfiguration from "../../pages/settings/WalletConfiguration";

import LeaveManagement from "../../pages/others/LeaveManagement/CancelableLeaves";
import ChangePassword from "../../pages/others/ChangePassword";
import AccountBalance from "../../pages/others/AccountBalance";
import MyLeaves from "../../pages/others/Leaves";
import LeaveSummary from "../../pages/others/LeaveSummary";
import RefferalManagement from "../../pages/settings/Extensions/RefferalManagement";
import GoogleMerchantCenter from "../../pages/settings/Extensions/GoogleMerchantCenter";
import ProductVariantSupport from "../../pages/catalogue/Products/ProductVariantSupport";
import Slots from "../../pages/stores/slots/EditSlots";
import {
  isExtensionEnabled,
  getPermissions,
  isEnterprise,
  modifyMenuForEnterprise,
  modifyEnterprisePermissions,
  getExtensionDetails,
  firstSubmenu,
  saveSession,
} from "../../lib/auth";
import API, { isInternal } from "../../lib/api";
import { get, set } from "../../lib/storage";
import { roleMapping } from "../../lib/commonlyused";
import OrderManagement from "../../pages/settings/OrderManagement";
import IntegrationSettings from "../../pages/settings/IntegrationSettings";
import { GOOGLE_MAP_DEFAULT_KEY } from "../../config/app";
import LazyComponentLoader, { LazyComponent } from "../LazyComponentLoader";
import { getSession } from "../../lib/auth";
import OrderPackageConfig from "../../pages/settings/OrderPackageConfig";
import CourierSupportForm from "../../pages/settings/CourierSupportForm";
import isEmpty from "lodash.isempty";
import ProductRestrictions from "../../pages/settings/ProductRestrictions";
import { isMarketPlaceOwner } from "../../lib/marketPlace";
import UserActivityLogs from "../../pages/settings/Users/UserActivityLogs";

const OnboardingContainer = LazyComponent(() =>
  import(/* webpackChunkName: "onboarding"*/ "../../pages/onboarding")
);

function hasAccess({ endpoints, extensions = [] }) {
  let hasExtensionsEnabled = extensions
    .map(isExtensionEnabled)
    .reduce((acc, value) => acc && value, true);
  let hasApiPermissions =
    !endpoints || !endpoints.length
      ? true
      : Object.values(getPermissions(endpoints)).reduce((acc, value) => {
          if (value instanceof Object) {
            return (
              acc &&
              Object.values(value).reduce((acc, value) => acc || value, false)
            );
          } else {
            return acc && value;
          }
        }, true);
  return hasApiPermissions && hasExtensionsEnabled;
}

export default class Router extends Component {
  constructor(props) {
    super(props);
    let url = new URL(window.location);
    if (url.searchParams.get("guid")) {
      this.state = {
        refresh: true,
      };
    } else {
      this.state = {
        refresh: false,
      };
    }
  }

  getCatalogueMenu = () => {
    const catalogueMenu = [
      {
        slug: "dashboard",
        endpoints: ["catalogue-service/stats", "account-service/extension"],
      },
      {
        slug: "brands",
        endpoints: ["catalogue-service/brand", "account-service/extension"],
        extensions: ["MultiBrandSupport"],
      },
      {
        slug: "categories",
        endpoints: ["catalogue-service/category", "account-service/extension"],
      },
      {
        slug: "tags",
        endpoints: ["catalogue-service/tag", "account-service/extension"],
        extensions: ["ProductTagSupport"],
      },
      {
        slug: "substitution-groups",
        endpoints: [
          "catalogue-service/substitution-group",
          "account-service/extension",
        ],
        extensions: ["SubstitutionGroups"],
      },
    ];

    if (isMarketPlaceOwner()) {
      catalogueMenu.splice(3, 0, {
        slug: "master-catalogue",
        endpoints: [
          "catalogue-service/category",
          "catalogue-service/product",
          "catalogue-service/permissible-seller",
          "catalogue-service/product-upload",
          "account-service/extension",
        ],
        extensions: ["MarketPlace"],
      });
      catalogueMenu.splice(4, 0, {
        slug: "seller-catalogue",
        endpoints: [
          "catalogue-service/category",
          "catalogue-service/product",
          "catalogue-service/seller-product-request",
          // "catalogue-service/product-upload",
          "account-service/extension",
        ],
        extensions: ["MarketPlace"],
      });
    } else {
      catalogueMenu.splice(3, 0, {
        slug: "products",
        endpoints: [
          // "catalogue-service/category",
          "catalogue-service/product",
          // "catalogue-service/product-upload",
          // "account-service/extension",
          "catalogue-service/seller-product-request",
        ],
      });
    }

    return catalogueMenu;
  };

  render() {
    const { props } = this;
    // Only list mandatory API endpoints/extensions here
    const requiredPermissions = {
      user: [
        {
          slug: "account-balance",
          endpoints: ["billing-service/transaction", "billing-service/balance"],
        },
        {
          slug: "my-leaves",
          endpoints: ["account-service/user-leave"],
          extensions: ["HrManagement"],
        },
        {
          slug: "leave-management",
          endpoints: ["account-service/user-leave"],
          extensions: ["HrManagement"],
        },
        {
          slug: "leave-summary",
          endpoints: ["account-service/user-leave"],
          extensions: ["HrManagement"],
        },
        {
          slug: "change-password",
          endpoints: ["account-service/change-password"],
        },
        {
          slug: "logout",
        },
      ],
      operations: [
        {
          slug: "dashboard",
          endpoints: [
            "order-service/stats",
            "order-service/order-count",
            "customer-service/stats",
          ],
        },
        // {
        //   slug: 'customers',
        //   endpoints: [
        //     'customer-service/customer'
        //   ]
        // },
        {
          slug: "orders",
          endpoints: ["order-service/order", "account-service/config"],
        },
        {
          slug: "disputes",
          endpoints: ["order-service/order/x/dispute"],
          extensions: ["OrderDisputes"],
        },
        {
          slug: "returns",
          endpoints: ["order-service/order/x/returns"],
          extensions: ["OrderDisputes"], // TODO: Change this later
        },
        {
          slug: "picking-queue",
          endpoints: ["order-service/picking-queue"],
          extensions: ["InStoreProcessing"],
        },
        {
          slug: "store-slot-config",
          endpoints: [
            // 'order-service/store-slot-rule',
            "account-service/extension",
            "order-service/store",
          ],
          extensions: ["DeliverySlots"],
        },
        {
          slug: "rack-management",
          endpoints: ["order-service/picking-sequence"],
          extensions: ["InStoreProcessing"],
        },
        {
          slug: "pickers",
          endpoints: [
            "account-service/employee",
            "account-service/designation",
            "account-service/role",
          ],
          extensions: ["InStoreProcessing"],
        },
        {
          slug: "capacity-planning",
          endpoints: ["logistics-service/zone-capacity", "order-service/slot"],
          extensions: ["CapacityPlanning"],
        },
        {
          slug: "slot-charges",
          endpoints: ["order-service/slot-charge", "order-service/slot"],
          extensions: ["DeliverySlots", "SlotCharges"],
        },
        {
          slug: `click-collect-orders?preferredDate=${moment().format(
            "YYYY-MM-DD"
          )}&orderType=PICKUP`,
          endpoints: ["order-service/order"],
        },
      ],
      catalogue: this.getCatalogueMenu(),
      logistics: [
        {
          slug: "vehicles",
          endpoints: ["logistics-service/vehicle"],
          extensions: ["LogisticsSupport"],
        },
        {
          slug: "vehicle-planning",
          endpoints: [
            "logistics-service/vehicle-planner",
            "logistics-service/vehicle-attendance",
            "logistics-service/vehicle",
          ],
          extensions: ["LogisticsSupport"],
        },
        {
          slug: "trips",
          endpoints: ["logistics-service/trip", "account-service/config"],
          extensions: ["LogisticsSupport"],
        },
        {
          slug: "trip-planner",
          endpoints: [
            "logistics-service/automation",
            "logistics-service/delivery-area",
            "logistics-service/trip",
          ],
          extensions: ["LogisticsSupport"],
        },
        {
          slug: "manage-runners",
          endpoints: ["account-service/employee", "account-service/role"],
          extensions: ["LogisticsSupport"],
        },
      ],
      sellers: [
        {
          slug: "seller",
          endpoints: ["account-service/seller"],
          extensions: ["MarketPlace"],
        },
        {
          slug: "user",
          endpoints: ["account-service/employee"],
          extensions: ["MarketPlace"],
        },
      ],
      hr: [
        {
          slug: "employees",
          endpoints: [
            "account-service/employee",
            "account-service/designation",
          ],
          extensions: ["HrManagement"],
        },
        {
          slug: "designations",
          endpoints: ["account-service/designation"],
          extensions: ["HrManagement"],
        },
        {
          slug: "attendance-summary",
          endpoints: [
            "account-service/attendance-summary",
            "account-service/employee",
          ],
          extensions: ["HrManagement"],
        },
      ],
      marketing: [
        {
          slug: "pages",
          endpoints: ["account-service/static-page"],
          extensions: ["Marketing"],
        },
        {
          slug: "blog",
          endpoints: ["blog-service/blog"],
          extensions: ["Marketing"],
        },
        {
          slug: "recipe",
          endpoints: ["recipe-service/recipe"],
          extensions: ["Marketing"],
        },
        {
          slug: "coupons",
          endpoints: ["promo-service/coupon"],
          extensions: ["Marketing"],
        },
        {
          slug: "campaigns",
          endpoints: ["promo-service/campaign"],
          extensions: ["Campaigns"],
        },
        {
          slug: "offers",
          endpoints: ["offer-service/offer"],
          extensions: ["Marketing"],
        },
        {
          slug: "web-banners",
          endpoints: ["promo-service/banner"],
          extensions: ["Marketing"],
        },
        {
          slug: "rating-reviews",
          endpoints: [
            "catalogue-service/product-rating",
            "order-service/order-rating",
          ],
          extensions: ["ProductRatings"],
        },
      ],
      analytics: [
        {
          slug: "orders-analytics",
          endpoints: ["analytics-service/metrics"],
          extensions: ["ReportsAndAnalytics"],
        },
        {
          slug: "reports",
          endpoints: ["report-service/reports"],
          extensions: ["ReportsAndAnalytics"],
        },
      ],
      "customer-support": [
        {
          slug: "dashboard",
          endpoints: [
            "customer-service/phone",
            "customer-service/email",
            "customer-service/customer",
            "order-service/slot",
            "order-service/order",
            "account-service/config",
          ],
          extensions: ["CustomerSupport"],
        },
        {
          slug: "customers",
          endpoints: ["customer-service/customer"],
          extensions: ["CustomerSupport"],
        },
        {
          slug: "orders",
          endpoints: [
            "order-service/order",
            "order-service/slot",
            "account-service/config",
            "customer-service/customer",
          ],
          extensions: ["CustomerSupport"],
        },
      ],
      settings: [
        {
          slug: "basic-information",
          endpoints: ["account-service/config"],
        },
        {
          slug: "contact-details",
          endpoints: ["account-service/config"],
        },
        {
          slug: "themes",
          endpoints: ["account-service/page", "account-service/theme"],
        },
        {
          slug: "extensions",
          endpoints: ["account-service/extension"],
        },
        {
          slug: "mobile-apps",
          endpoints: ["account-service/config"],
          extensions: ["MobileApps"],
        },
        {
          slug: "notifications",
        },
        {
          slug: "communication-configuration",
          endpoints: ["config-service/config"],
        },
        {
          slug: "order-config",
        },
        {
          slug: "integration-settings",
        },
        {
          slug: "order-package-config",
          endpoints: ["order-service/package"],
        },
        {
          slug: "courier-support",
        },
        {
          slug: "referral-management",
          extensions: ["ReferralManagement"],
        },
        {
          slug: "google-merchant-center",
          extensions: ["GoogleMerchantCenter"],
        },
        {
          slug: "product-variant-support",
          extensions: ["MultiVariantSupport"],
        },
        {
          slug: "product-restrictions",
          extensions: ["ProductRestrictions"],
        },
        {
          slug: "user-activity-logs",
          extensions: ["UserLogs"],
        },
      ],
    };

    if (isEnterprise()) {
      requiredPermissions.settings = modifyEnterprisePermissions();
    }

    const getPagePermissions = function (url) {
      let path = url.split("/").filter(Boolean);
      let permissions = requiredPermissions[path[0]].find(
        (obj) => obj.slug === path[1]
      );
      return (({ endpoints, extensions }) => ({ endpoints, extensions }))(
        permissions
      );
    };

    let menu = Object.assign(
      {},
      ...Object.keys(requiredPermissions).map((key) => {
        let permissions = requiredPermissions[key];
        return {
          [key]: permissions.filter(hasAccess).map(({ slug }) => slug),
        };
      })
    );

    // Prune menu sections which have no links in them
    for (let section in menu) {
      if (!menu[section].length) {
        delete menu[section];
      }
    }

    const isLoggedIn = props.isLoggedIn;

    const getUserDetails = () => {
      let userAPI = new API({ url: "/account-service/user" });
      return userAPI
        .get()
        .then((response) => {
          return response.data.user[0];
        })
        .then(({ id }) => {
          let user = new API({ url: `/account-service/user/${id}` });
          return user.get();
        })
        .catch((error) => {
          console.error(error);
        });
    };
    const getBackOfficeAPIDetails = () => {
      let backOfficeApi = new API({ url: "/config-service/config/backOffice" });
      return backOfficeApi.get();
    };

    const getOrganizationDetails = (organizationId) => {
      const organizationAPI = new API({
        url: `/account-service/organization/${organizationId}`,
      });
      return organizationAPI.get();
    };

    const PrivateRoute = ({ component: Component, isLazy, ...rest }) => {
      let endpoints = [];
      let extensions = [];
      let hasPageAccess = true;
      let location = new URL(JSON.parse(JSON.stringify(window.location)).href);
      let redirectedFrom = location.searchParams.get("redirectedFrom");
      let organizationId = location.searchParams.get("organizationId");
      redirectedFrom && set("redirectedFrom", redirectedFrom);
      if (location.searchParams.get("guid") && this.state.refresh) {
        if (isInternal()) {
          Promise.all([
            getUserDetails(),
            getOrganizationDetails(organizationId),
            getBackOfficeAPIDetails(),
          ])
            .then(
              ([userResponse, organizaitonResponse, backOfficeResponse]) => {
                let user = userResponse.data.user;
                let key =
                  backOfficeResponse.data &&
                  backOfficeResponse.data.backOffice &&
                  backOfficeResponse.data.backOffice.googleMapsApiKey;
                set("googleMapsApiKey", key || GOOGLE_MAP_DEFAULT_KEY);
                saveSession({
                  user: user,
                  organization: organizaitonResponse.data.organization,
                });
                this.setState({
                  refresh: false,
                });
              }
            )
            .catch((error) => {
              console.error(error);
            });
        } else {
          const api = new API({ url: "/account-service/me" });
          const extnApi = new API({ url: "/account-service/extension" });
          Promise.all([api.get(), extnApi.get()])
            .then(
              ([response, extnResponse]) => {
                saveSession({
                  user: response.data.user,
                  organization: {
                    extension: extnResponse.data.extension,
                    currency: { symbol: "" },
                  },
                });
                this.setState({
                  refresh: false,
                });
              },
              (error) => {
                throw error;
              }
            )
            .catch((error) => {
              console.error(error);
            });
        }
      }
      let hasPermissionsInfo = Boolean(getPermissions());
      let redirectToLogin = !isLoggedIn || !hasPermissionsInfo;
      if (location.searchParams.get("guid") && hasPermissionsInfo) {
        redirectToLogin = false;
      }
      if (endpoints.length || extensions.length) {
        let temp = getPagePermissions(rest.path);
        endpoints = temp.endpoints;
        extensions = temp.extensions;
        hasPageAccess = hasAccess({ endpoints, extensions });
      }
      if (isEnterprise()) {
        menu = modifyMenuForEnterprise(menu);
      }
      if (get("user") && JSON.parse(get("user"))) {
        let user = JSON.parse(get("user"));
        if (!user.isOwner) {
          delete menu.settings;
        }
        let roles =
          user.designation && user.designation.roles && user.designation.roles;
        if (roles && roles.length === 1) {
          let role = roles[0];
          if (role.name === "Transport Coordinator") {
            delete menu.hr;
          }
          if (role.name === "COMPLETE ORDER") {
            delete menu["customer-support"];
          }
        }
      }
      return (
        !this.state.refresh && (
          <Route
            {...rest}
            render={(props) =>
              !redirectToLogin ? (
                hasPageAccess ? (
                  isLazy ? (
                    <LazyComponentLoader Component={Component} {...props} />
                  ) : (
                    <Component
                      {...props}
                      menu={menu}
                      rerender={() => {
                        // TODO: Provide a better way to figure out how to re-render when permissions change,
                        // perhaps by maintaining a state which contains permissions
                        this.setState({});
                      }}
                    />
                  )
                ) : (
                  <MissingPage />
                )
              ) : (
                <Redirect
                  to={{
                    pathname: isLoggedIn ? "/user/logout" : "/login",
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
        )
      );
    };
    const submenuRedirect = (mainMenuItem, submenu) => {
      let url = mainMenuItem;
      let sub = menu[mainMenuItem]
        ? menu[mainMenuItem].indexOf(submenu) > -1
          ? submenu
          : menu[mainMenuItem][0]
        : submenu;
      return `/${url}/${sub}`;
    };

    const getFirstRoute = (menuList = {}) => {
      let newMenuList = { ...menuList };
      delete newMenuList.user;
      const routeValue = `/${Object.keys(newMenuList)?.[0]}`;
      return isEmpty(newMenuList) ? "/operations/dashboard" : routeValue;
    };

    const user = JSON.parse(get("user"));
    const role =
      user &&
      user.designation &&
      user.designation.roles &&
      user.designation.roles[0]
        ? user.designation.roles[0]
        : {};
    let roleObject = roleMapping.find((mapping) => mapping.name === role.name);
    let roleRedirect = roleObject ? roleObject.redirect : null;
    const { organization } = getSession();
    roleRedirect =
      !organization?.industry && !isEnterprise()
        ? "/onboarding"
        : roleRedirect || getFirstRoute(menu);

    const isMarketOwner = isMarketPlaceOwner();

    return (
      <Switch>
        {/* Default second level menu option for each top level menu */}
        <Redirect exact from="/" to={roleRedirect} />
        <Redirect
          exact
          from="/analytics"
          to={submenuRedirect("analytics", "orders-analytics")}
        />
        {
          <Redirect
            exact
            from="/marketing"
            to={submenuRedirect("marketing", "pages")}
          />
        }
        <Redirect
          exact
          from="/operations"
          to={submenuRedirect("operations", "dashboard")}
        />
        <Redirect
          exact
          from="/logistics"
          to={submenuRedirect("logistics", "vehicles")}
        />
        <Redirect
          exact
          from="/sellers"
          to={submenuRedirect("sellers", "seller")}
        />
        {!isEnterprise() && (
          <Redirect
            exact
            from="/settings"
            to={submenuRedirect("settings", "basic-information")}
          />
        )}
        {isEnterprise() && (
          <Redirect exact from="/settings" to={`/settings/${firstSubmenu}`} />
        )}
        <Redirect
          exact
          from="/customer-support"
          to={submenuRedirect("customer-support", "dashboard")}
        />
        <Redirect
          exact
          from="/catalogue"
          to={submenuRedirect("catalogue", "dashboard")}
        />
        <Redirect
          exact
          from="/user"
          to={submenuRedirect("user", "account-balance")}
        />
        <Redirect exact from="/hr" to={submenuRedirect("hr", "employees")} />
        <Redirect
          exact
          from="/marketing/unique-coupons"
          to="/marketing/coupons"
        />{" "}
        {/* Becoz bulk coupons dont have listing */}
        <Redirect
          exact
          from="/operations/customers"
          to="/customer-support/customers"
        />
        <Redirect
          exact
          from="/operations/customers/view/:id"
          to="/customer-support/customers/view/:id"
        />
        {isExtensionEnabled("InStoreProcessing") && (
          <Redirect
            exact
            from="/settings/instore"
            to={`/settings/extensions/${
              getExtensionDetails("InStoreProcessing").id
            }`}
          />
        )}
        {isExtensionEnabled("TawkToLiveChat") && (
          <Redirect
            exact
            from="/settings/tawk"
            to={`/settings/extensions/${
              getExtensionDetails("TawkToLiveChat").id
            }`}
          />
        )}
        {isExtensionEnabled("EntityMetaData") && (
          <Redirect
            from="/settings/metadata"
            to={`/settings/extensions/${
              getExtensionDetails("EntityMetaData").id
            }`}
          />
        )}
        <Route path="/signUp" component={SignUp} />
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ResetPassword} />
        <Route path="/user/logout" component={Logout} />
        <Route path="/verify" component={Verify} />
        <Route path="/account-created" component={AccountCreated} />
        {/* For each route, we should have exactly one component */}
        <PrivateRoute
          path="/onboarding"
          component={OnboardingContainer}
          isLazy={true}
        />
        <PrivateRoute path="/payment-response" component={PaymentResponse} />
        <PrivateRoute
          path="/catalogue/dashboard"
          component={CatalogueDashboard}
        />
        <PrivateRoute
          path="/catalogue/categories/tree-view"
          component={CategoryTree}
        />
        <PrivateRoute path="/catalogue/categories" component={Categories} />
        <PrivateRoute path="/catalogue/brands" component={Brands} />
        <PrivateRoute
          path="/catalogue/products/:action?/:id?"
          component={Products}
          exact
        />
        {isMarketOwner && (
          <PrivateRoute
            path="/catalogue/master-catalogue/:action?/:id?"
            component={Products}
            exact
          />
        )}
        {isMarketOwner && (
          <PrivateRoute
            path="/catalogue/seller-catalogue/:action?/:id?"
            component={Products}
            exact
          />
        )}
        <PrivateRoute path="/catalogue/tags" component={Tags} exact />
        <PrivateRoute
          path="/catalogue/substitution-groups/:action?/:id?"
          component={SubstitutionGroups}
        />
        <PrivateRoute
          path="/operations/dashboard"
          component={OperationsDashboard}
        />
        <PrivateRoute
          path="/operations/picking-queue"
          component={OrderPickingQueue}
        />
        {/* <PrivateRoute path='/operations/customers/:action?/:id?' component={Customers} /> */}
        <PrivateRoute
          path="/operations/orders/add"
          component={OrderAddForm}
          exact
        />
        <PrivateRoute path="/operations/orders/:id?" component={Orders} exact />
        <PrivateRoute
          path="/operations/orders/order-log/:id"
          component={OrderLog}
          exact
        />
        <PrivateRoute
          path="/operations/orders/edit-packed/:id"
          component={EditPackedOrder}
          exact
        />
        <PrivateRoute path="/operations/stock-status" component={NotDone} />
        <PrivateRoute
          path="/operations/rack-management"
          component={RackManagement}
        />
        <PrivateRoute path="/operations/disputes" component={Disputes} />
        <PrivateRoute
          path="/operations/orders/dispute/:id"
          component={FileDispute}
          exact
        />
        <PrivateRoute path="/operations/returns" component={Returns} />
        <PrivateRoute path="/operations/pickers" component={Pickers} />
        <PrivateRoute
          path="/operations/pickers-today"
          component={PickersToday}
        />
        <PrivateRoute
          path="/operations/store-slot-config"
          component={StoreSlotConfigTable}
        />
        <PrivateRoute
          path="/operations/orders/file-for-return/:id"
          component={FileReturn}
          exact
        />
        <PrivateRoute
          path="/operations/orders/register-payment/:id"
          component={OrderRegisterPayments}
          exact
        />
        <PrivateRoute
          path="/operations/capacity-planning"
          component={CapacityPlanning}
          exact
        />
        <PrivateRoute
          path="/operations/capacity-planning/today"
          component={Today}
          exact
        />
        <PrivateRoute path="/operations/slot-charges" component={SlotCharges} />
        <PrivateRoute
          path="/operations/click-collect-orders"
          component={ClickCollectOrders}
        />
        <PrivateRoute
          path="/operations/orders/dispatch/:id"
          component={OrderDispatchForm}
          exact
        />
        <PrivateRoute
          path="/operations/orders/pack-order/:id"
          component={PackOrder}
          exact
        />
        <PrivateRoute path="/logistics/dashboard" component={NotDone} />
        <PrivateRoute path="/logistics/vehicles" component={Vehicles} />
        <PrivateRoute
          path="/logistics/vehicle-planning"
          component={VehiclePlanner}
        />
        <PrivateRoute
          path="/logistics/vehicle-vendor"
          component={VehicleVendor}
        />
        <PrivateRoute path="/logistics/trips" component={Trips} exact />
        <PrivateRoute
          path="/logistics/trips/:id"
          component={TripDetails}
          exact
        />
        <PrivateRoute
          path="/logistics/trips/trip-payments/:id"
          component={TripPaymentsForm}
          exact
        />
        <PrivateRoute
          path="/logistics/trip-planner"
          component={TripPlanner}
          exact
        />
        <PrivateRoute
          path="/logistics/manage-runners"
          component={ManageRE}
          exact
        />
        <PrivateRoute
          path="/logistics/trip-planner/table-view"
          component={TripPlannerTable}
        />
        <PrivateRoute
          path="/customer-support/dashboard"
          component={CsDashboard}
        />
        <PrivateRoute
          path="/customer-support/customers/:action?/:id?"
          component={CsCustomers}
        />
        <PrivateRoute
          path="/customer-support/orders/:id?"
          component={CsOrders}
          exact
        />
        <PrivateRoute
          path="/customer-support/orders/order-log/:id"
          component={OrderLog}
          exact
        />
        <PrivateRoute
          path="/customer-support/orders/file-for-return/:id"
          component={FileReturn}
          exact
        />
        <PrivateRoute
          path="/customer-support/order/order-return/:id"
          component={FileReturn}
          exact
        />
        {/* <PrivateRoute path='/customer-support/calls' component={CsCalls} /> */}
        <PrivateRoute path="/customer-support/emails" component={NotDone} />
        <PrivateRoute
          path="/customer-support/orders/dispute/:id"
          component={FileDispute}
          exact
        />
        <PrivateRoute
          path="/customer-support/orders/register-payment/:id"
          component={OrderRegisterPayments}
          exact
        />
        <PrivateRoute
          path="/sellers/seller/:action?/:id?"
          component={Sellers}
          exact
        />
        <PrivateRoute
          path="/sellers/user/:action?/:id?"
          component={SellerUsers}
          exact
        />
        <PrivateRoute
          path="/customer-support/orders/pack-order/:id"
          component={PackOrder}
          exact
        />
        {isExtensionEnabled("Marketing") && (
          <PrivateRoute path="/marketing/dashboard" component={NotDone} />
        )}
        {isExtensionEnabled("Marketing") && (
          <PrivateRoute
            exact
            path="/marketing/coupons/view/:id"
            component={CouponAnalytics}
          />
        )}
        {isExtensionEnabled("Marketing") && (
          <PrivateRoute
            exact
            path="/marketing/coupons/:action?/:id?"
            component={Coupons}
          />
        )}
        {isExtensionEnabled("Marketing") && (
          <PrivateRoute
            exact
            path="/marketing/unique-coupons/:action?/:id?"
            component={BulkCoupons}
          />
        )}
        <PrivateRoute
          path="/marketing/offers/:action?/:id?"
          component={Offers}
        />
        {isExtensionEnabled("Marketing") && (
          <PrivateRoute
            path="/marketing/campaigns/:action?/:id?"
            component={Campaigns}
          />
        )}
        {isExtensionEnabled("Marketing") && (
          <PrivateRoute
            path="/marketing/blog/:action?/:id?"
            component={Blogs}
          />
        )}
        {isExtensionEnabled("Marketing") && (
          <PrivateRoute
            path="/marketing/recipe/:action?/:id?"
            component={Recipe}
          />
        )}
        {isExtensionEnabled("Marketing") && (
          <PrivateRoute
            path="/marketing/pages/:action?/:id?"
            component={Pages}
          />
        )}
        {isExtensionEnabled("Marketing") && (
          <PrivateRoute
            path="/marketing/web-banners"
            component={ManageBanners}
          />
        )}
        {isExtensionEnabled("ProductRatings") && (
          <PrivateRoute
            path="/marketing/rating-reviews"
            component={RatingAndReviews}
          />
        )}
        {isExtensionEnabled("GoogleMerchantCenter") && (
          <PrivateRoute
            path="/settings/google-merchant-center"
            component={GoogleMerchantCenter}
          />
        )}
        <PrivateRoute
          path="/settings/product-variant-support"
          component={ProductVariantSupport}
        />
        <PrivateRoute
          path="/settings/user-activity-logs"
          component={UserActivityLogs}
        />
        <PrivateRoute path="/hr/designations" component={Designations} />
        <PrivateRoute
          path="/hr/employees/:action?/:id?"
          component={UserShifts}
          exact
        />
        <PrivateRoute
          path="/hr/attendance-summary"
          component={AttendanceSummary}
        />
        <PrivateRoute
          path="/analytics/orders-analytics"
          component={OrdersAnalytics}
          exact
        />
        <PrivateRoute
          path="/analytics/reports"
          component={AnalyticsReport}
          exact
        />
        {!isEnterprise() && (
          <PrivateRoute
            path="/settings/basic-information"
            component={BasicInformation}
          />
        )}
        {!isEnterprise() && (
          <PrivateRoute path="/settings/catalogue" component={NotDone} />
        )}
        {!isEnterprise() && (
          <PrivateRoute
            path="/settings/contact-details"
            component={SocialMedia}
          />
        )}
        <PrivateRoute
          exact
          path="/settings/extensions/:id"
          component={ExtensionDetailsForm}
        />
        {!isEnterprise() && (
          <PrivateRoute path="/settings/extensions" component={Extensions} />
        )}
        <PrivateRoute path="/settings/users/:action?/:id?" component={Users} />
        <PrivateRoute
          path="/settings/customer-tags/:action?/:id?"
          component={CustomerTags}
        />
        <PrivateRoute
          path="/settings/stores/:action?/:id?"
          component={Stores}
        />
        <PrivateRoute path="/settings/delivery-area" component={DeliveryArea} />
        {isExtensionEnabled("ReferralManagement") && (
          <PrivateRoute
            path="/settings/referral-management"
            component={RefferalManagement}
          />
        )}
        {!isEnterprise() && (
          <PrivateRoute path="/settings/logistics" component={NotDone} />
        )}
        <PrivateRoute path="/settings/analytics" component={Analytics} />
        {!isEnterprise() && (
          <PrivateRoute
            path="/settings/notifications"
            component={Notifications}
          />
        )}
        {!isEnterprise() && (
          <PrivateRoute
            path="/settings/customer-communication"
            component={NotDone}
          />
        )}
        {
          <PrivateRoute
            path="/settings/themes/:action?"
            component={Themes}
            exact
          />
        }
        <PrivateRoute path="/settings/mobile-apps" component={MobileApps} />
        <PrivateRoute
          path="/settings/payment-configuration"
          component={PaymentConfiguration}
        />
        <PrivateRoute path="/settings/slots" component={Slots} />
        {isExtensionEnabled("StockOverride") && (
          <PrivateRoute
            path="/settings/store-configuration"
            component={StoreConfiguration}
          />
        )}
        {<PrivateRoute path="/settings/seo" component={SEO} />}
        <PrivateRoute
          path="/settings/logistics-configuration"
          component={LogisticsConfig}
        />
        {isExtensionEnabled("MultipleAndPartialOrderPicking") && (
          <PrivateRoute
            path="/settings/picking-configuration"
            component={PickupConfiguration}
          />
        )}
        {isExtensionEnabled("SearchOverride") && (
          <PrivateRoute
            path="/settings/search-configuration"
            component={SearchConfiguration}
          />
        )}
        {isExtensionEnabled("ProductRestrictions") && (
          <PrivateRoute
            path="/settings/product-restrictions"
            component={ProductRestrictions}
          />
        )}
        {isExtensionEnabled("Wallet") && (
          <PrivateRoute
            path="/settings/wallet-configuration"
            component={WalletConfiguration}
          />
        )}
        <PrivateRoute
          path="/settings/communication-configuration"
          component={CommunicationConfig}
        />
        <PrivateRoute
          path="/settings/order-config"
          component={OrderManagement}
        />
        <PrivateRoute
          path="/settings/order-package-config"
          component={OrderPackageConfig}
          exact
        />
        <PrivateRoute
          path="/settings/courier-support"
          component={CourierSupportForm}
        />
        <PrivateRoute
          path="/settings/integration-settings"
          component={IntegrationSettings}
        />
        <PrivateRoute path="/user/change-password" component={ChangePassword} />
        <PrivateRoute path="/user/account-balance" component={AccountBalance} />
        <PrivateRoute
          path="/user/leave-management"
          component={LeaveManagement}
        />
        <PrivateRoute path="/user/my-leaves" component={MyLeaves} />
        <PrivateRoute path="/user/leave-summary" component={LeaveSummary} />
        <Route component={MissingPage} />
      </Switch>
    );
  }
}
