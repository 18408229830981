import React, { Component } from "react";
import Upload from "../../../../components/FileUpload";
import API from "../../../../lib/api";
import { getMessage } from "../../../../lib/translator";
import csvIcon from "../../../catalogue/Products/ProductUpload/csv-icon.svg";
import uploadIcon from "../../../catalogue/Products/ProductUpload/upload-products.svg";
import "./style.css";

function downloadCsv(data) {
  let csv = "";
  csv += data.join(",");
  csv += "\n";
  var hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
  hiddenElement.target = "_blank";
  hiddenElement.download = "categories.csv";
  hiddenElement.click();
}

export default class CategoryUpload extends Component {
  constructor(props) {
    super(props);
    this.getSampleCsv = this.getSampleCsv.bind(this);
  }

  getSampleCsv(e) {
    e && e.preventDefault();
    const api = new API({ url: "/catalogue-service/category-upload" });
    api
      .get()
      .then(
        (response) => {
          downloadCsv(response.data.fileUpload || []);
        },
        (error) => {
          this.setState({ uploadError: error.message });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    return (
      <div className="category-upload">
        <div className="bordered-box">
          <div className="text-center">
            <img src={uploadIcon} width="140" alt="" />
          </div>
          <Upload
            name="categoryUpload"
            accept=".csv, text/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.ms-excel, application/vnd.msexcel"
            placeholder={getMessage("productUpload.uploadFileText")}
            uploadButtonText="Upload"
            cancelText="Cancel"
            uploadUrl="/catalogue-service/category-upload"
            validationStrings={{
              invalidFileType: getMessage("input.invalidFileType"),
            }}
            strings={{
              defaultMessage: getMessage("fileUpload.importCsv.heading"),
              progressMessage: getMessage("fileUpload.importedCsv.heading"),
              completionMessage: getMessage("categories.import.successText"),
            }}
            icon={csvIcon}
          />
        </div>
        <div className="text-center">
          <button className="download-link" onClick={this.getSampleCsv}>
            {getMessage("productUpload.csvDownloadText")}
          </button>
        </div>
      </div>
    );
  }
}
