const data = {
  "sellers.seller.table.action.name": "Name",
  "sellers.seller.table.action.contact": "Contact",
  "sellers.seller.table.action.address": "Address",
  "sellers.seller.table.action.status": "Status",
  "sellers.seller.table.action.actions": "Actions",
  "sellers.seller.helpItems.message": "No Seller Yet",
  "sellers.seller.form.edit.heading": "Edit Seller",
  "sellers.seller.form.add.heading": "Add Seller",
  "seller.seller.form.add.text": "Add Sellers",
  "sellers.seller.form.add.bulk.text": "Bulk Upload",
  "sellers.import.successText":
    "CSV File Uploaded Successfully! Sellers will be added and appear after 15 minutes",
  "sellerUpload.csvDownloadText": "Download Sample CSV",
  "sellerUpload.uploadFileText": "Click here to upload the file",
  "sellers.seller.form.title.heading": "SELLER NAME",
  "sellers.seller.form.title.placeholder": "Enter seller name",
  "sellers.seller.form.title.requiredMessage": "Your seller must have a name",
  "sellers.seller.form.phone.heading": "Support Phone",
  "sellers.seller.form.phone.placeholder": "Enter Phone",
  "sellers.seller.form.email.heading": "Support Email",
  "sellers.seller.form.email.placeholder": "Enter Email",
  "sellers.seller.form.address.heading": "Complete Address",
  "sellers.seller.form.address.placeholder": "Enter Your Address",
  "sellers.seller.form.description.title.heading": "Description",
  "sellers.seller.form.description.title.pleaceholder":
    "Like Your Vision Mission etc",
  "sellers.seller.form.image.logo.heading": "LOGO",
  "sellers.confirm.text": "Confirm",
  "sellers.cancel.text": "Cancel",
  "seller.status.active": "Activate",
  "seller.status.inactive": "Inactivate",
  "seller.status.edit": "Edit",
  "seller.status.delete": "Delete",
  "sellers.user.password.heading": "password",
  "sellers.user.password.placeholder": "Enter Password",
  "sellers.user.add.text": "Add User",
  "sellers.user.add.nouser.text": "No User Yet",
  "sellers.user.edit.text": "Edit User",
  "sellers.user.form.name.heading": "Name",
  "sellers.user.form.name.pleceholder": "Enter Name",
  "sellers.user.form.sellername.heading": "Seller Name",
  "sellers.user.form.sellername.placeholder": "Enter Seller Name",
  "sellers.user.form.phone.heading": "Phone Number",
  "sellers.user.form.phone.placeholder": "Enter Phone Number",
  "sellers.user.form.email.heading": "Email",
  "sellers.user.form.email.placeholder": "Enter Email",
  "sellers.user.table.name": "Name",
  "sellers.user.table.sellername": "Seller Name",
  "sellers.user.table.email": "Email id",
  "sellers.user.table.action": "Actions",
  "user.action.edit": "Edit",
  "user.action.delete": "Delete",
  "user.action.reset.password": "Reset Password",
  "users.save.text": "Save",
  "seller.user.edit.text": "Edit User",
  "users.reset.title": "Reset password for",
  "users.reset.success.title": "Success",
  "users.reset.success.information": "Password has been successfully reset",
  "users.reset": "Okay",
};
export default data;
