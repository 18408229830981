const data = {
  "productUpload.csvDownloadText": "Download sample CSV",
  "productUpload.uploadFileText": "Click here to upload the file",
  "productUpload.addProduct": "Add Product",
  "productUpload.orText": "OR",
  "fileUpload.importCsv.heading": "Import CSV file",
  "fileUpload.importCsv.inprogress": "Imported Successfully",
  "fileUpload.importedCsv.heading": "Upload Now",
  "fileUpload.uploadedCsv.heading":
    "CSV File Uploaded Successfully! Products will appear after 15 minutes",
  "fileUpload.importKML.heading": "Import KML file",
  "fileUpload.importedKML.heading": "Upload Now",
  "fileUpload.uploadedKML.heading": "KML File Uploaded Successfully!",
  "category.upload.text": "Add Categories",
  "categories.import.successText":
    "  CSV File Uploaded Successfully! Categories will be added and appear after 15 minutes",
  "category.heading": "Categories",
  "category.form.name.heading": "Name",
  "category.form.name.placeholder": "Category Name",
  "category.form.tagline.heading": "tag line",
  "category.form.tagline.placeholder": "Enter Tag Line",
  "category.form.submitText": "Save",
  "category.form.cancelText": "Cancel",
  "category.form.parentCategory.heading": "Parent Category",
  "category.form.parentCategory.placeholder": "Select Parent Category",
  "category.form.description.heading": "Description",
  "category.form.description.placeholder": "Type Category Description",
  "category.form.edit.heading": "Edit Category",
  "category.form.add.heading": "Add Category",
  "category.form.uploadImage.heading": "Upload Image",
  "category.form.hideText": "Hide",
  "category.form.enableText": "Enable",
  "category.form.deleteText": "Delete",
  "category.header.name": "Name",
  "category.header.parentCategory": "Parent Category",
  "category.header.productCount": "Product Count",
  "category.header.products": "Products",
  "category.header.status": "Status",
  "category.header.image": "Image",
  "category.header.actions": "Actions",
  "category.action.edit": "Edit",
  "category.action.delete": "Delete",
  "category.action.seeProducts": "See Products",
  "category.helpItems.title": "From the blog",
  "category.helpItems.ppo.title": "Principles of product categorization",
  "category.helpItems.ppo.description":
    "Websites with helpful description get free visitors from search engines like Google.",
  "category.helpItems.poc.title": "Pitfalls of over-categorization",
  "category.helpItems.poc.description":
    "Images improves your landing page appeal which directly correlates with better conversion.",
  "category.helpItems.seo.title": "SEO impact of product categorization",
  "category.helpItems.seo.description":
    "Content marketing is a form of marketing focused on creating, publishing and distributing content for a targeted audience online.",
  "category.add.text": "Add Category",
  "category.tree.title": "Category Tree",
  "category.icons.tree-title": "Tree view",
  "category.icons.table-title": "Table view",
  "category.helpItems.message": "Your categories will be shown here.",
  "category.helpItems.submessage":
    "Proper categorization improves customer’s experience.",
  "category.filters.name.heading": "Name",
  "category.filters.status.heading": "Status",
  "category.filters.name.placeholder": "Search by Name",
  "category.filters.submitText": "Search",
  "category.filters.clearFiltersText": "Clear All",
  "category.filters.status.label.all": "All",
  "category.filters.status.label.enabled": "Enabled",
  "category.filters.status.label.disabled": "Disabled",
  "category.delete": "Are you sure?",
  "category.delete.confirm": "The category will be deleted",
  "category.delete.close": "Cancel",
  "category.delete.okay": "Okay",
  "category.add": "Add",
  "category.view": "View categories",
  "navigation.sequencing.heading": "Navigation Sequencing",
  "navigation.add.button": "+ Add",
  "navigation.nosequence.display": "No Sequence to display",
  "navigation.dropdown.item.addLink": "Add Link",
  "navigation.dropdown.item.addTag": "Add Tag",

  "brand.heading": "Brands",
  "brand.add.text": "Add Brand",
  "brand.edit.text": "Edit Brand",
  "brand.helpItems.message": "Your brands will be shown here.",
  "brand.header.logo": "Logo",
  "brand.header.brand": "Brand",
  "brand.header.productCount": "Product Count",
  "brand.header.status": "Status",
  "brand.header.actions": "Actions",
  "brand.status.enabled": "Enabled",
  "brand.status.disabled": "Disabled",
  "brand.status.hidden": "Hidden",
  "brand.action.edit": "Edit",
  "brand.action.delete": "Delete",
  "brand.action.seeProducts": "See products",
  "brand.filters.name.heading": "Name",
  "brand.filters.status.heading": "Status",
  "brand.filters.name.placeholder": "Search by Name",
  "brand.filters.submitText": "Search",
  "brand.filters.clearFiltersText": "Clear All",
  "brand.filters.status.label.all": "All",
  "brand.filters.status.label.enabled": "Enabled",
  "brand.filters.status.label.hidden": "Hidden",
  "brand.form.name.heading": "Name",
  "brand.form.name.placeholder": "Enter brand name",
  "brand.form.tagline.heading": "tagline",
  "brand.form.tagline.placeholder": "Enter Tag Line",
  "brand.form.image.heading": "Image",
  "brand.form.image.placeholder": "Click here to upload, or drag your image",
  "brand.form.hideText": "Hide",
  "brand.form.enableText": "Enable",
  "brand.form.deleteText": "Delete",
  "brand.form.submitText": "Save",
  "brand.form.cancelText": "Cancel",

  "product.table.heading": "Products",
  "product.form.add.heading": "Add Product",
  "product.form.edit.heading": "Edit Product",
  "product.filters.name.heading": "Name",
  "product.filters.name.placeholder": "Search by Name",
  "product.filters.clientId.heading": "Client Id",
  "product.filters.clientId.placeholder": "Search by Id",
  "product.filters.category.heading": "Category",
  "product.filters.category.placeholder": "Search by Category",
  "product.filters.brand.heading": "Brand",
  "product.filters.brand.placeholder": "Search by Brand",
  "product.filters.tag.heading": "Tag",
  "product.filters.tag.placeholder": "Search by Tag",
  "product.filters.status.heading": "Status",
  "product.filters.status.label.all": "All",
  "product.filters.status.label.enabled": "Enabled",
  "product.filters.status.label.disabled": "Disabled",
  "product.filters.submitText": "Search",
  "product.filters.clearFiltersText": "Clear All",
  "product.helpItems.message":
    "You have to create a catalogue of products for customers to buy from.",
  "product.helpItems.submessage":
    "Here you can provide all information about the products including pricing and upload some nice images.",
  "product.add.text": "Add Product",
  "add.label.text": "Add",
  "search.label.text": "Search",
  "product.search.and.add.heading": "Search & Add",
  "product.add.single.text": "Single product",
  "product.search.and.add.text": "Search & Add",
  "product.search.and.add.text.placeholder": "Search Products",
  "product.add.bulk.text": "Bulk upload",
  "deliveryArea.kml.upload.text": "KML upload",
  "product.selectType": "SELECT TYPE",
  "product.selectStore": "SELECT STORE",
  "product.type.catalogue": "Catalogue",
  "product.type.inventory": "Inventory Sheet",
  "product.downloadSuccessTitle": "Download {value}",
  "product.downloadOptionsTitle": "Download",
  "product.cancelDownloadCsv": "Cancel",
  "product.downloadDialogClose": "Okay",
  "product.downloadSuccessMessage":
    "Your {value} data will be sent to you via email in some time",
  "product.header.image": "Image",
  "product.header.name": "Name",
  "product.merchandise.id": "Merchandise Id",
  "product.header.primaryCategory": "Primary category",
  "product.header.sellername": "Seller",
  "product.header.status": "Status",
  "product.header.actions": "Actions",
  "product.action.edit": "Edit",
  "product.action.delete": "Delete",
  "product.action.enable": "Enable",
  "product.action.disable": "Hide",
  "product.action.view": "View",
  "product.heading": "Products",
  "product.request.approved": "Approved",
  "product.request.pending": "Pending",
  "product.request.rejected": "Rejected",
  "product.request.reject.form.heading": "Reject",
  "product.request.reject.form.label": "Enter your rejection reason*",
  "product.request.reject.form.placeholder": "Enter your Reason",
  "product.request.draft": "Draft",
  "product.form.name.heading": "Name",
  "product.form.name.placeholder": "Enter product name",
  "product.form.name.description":
    "Enter the name in the format 'product-name space number space kg/g/gm'",
  "product.form.brand.placeholder": "Brand name",
  "product.form.category.heading": "Category",
  "product.form.category.placeholder": "Enter category name",
  "product.form.category.addButton": "Add new category",
  "category.helper.text": "Group several products under one category",
  "product.form.category.requiredMessage": "This is not a valid category",
  "product.form.tag.heading": "Tag",
  "product.form.tag.placeholder": "Enter tag name",
  "product.form.tag.addButton": "Add new tag",
  "product.form.description.heading": "Description",
  "product.form.description.placeholder": "Enter product description",
  "product.form.variant.addButtonText": "Add Variant",
  "product.form.variant.editButtonText": "Edit",
  "product.form.variant.saveButton": "Save",
  "product.form.variant.cancelButton": "Cancel",
  "product.form.variant.name.heading": "Variant name",
  "product.form.variant.name.placeholder": "Variant",
  "product.form.variant.heading": "Variants",
  "product.form.variant.image.heading": "Upload product images",
  "product.form.variant.image.placeholder":
    "Click to upload, or drag images here",
  "product.form.variant.image.description": "",
  "product.form.variant.price.heading": "Price",
  "product.form.variant.price.placeholder": "0.00",
  "product.form.variant.discount.heading": "Discount",
  "product.form.variant.discount.placeholder": "0.00",
  "product.form.variant.sgst.placeholder": "0",
  "product.form.variant.barcodes.heading": "Barcodes",
  "product.form.variant.barcodes.placeholder": "Add Barcodes",
  "product.form.variant.availability.heading": "Availability",
  "product.form.variant.unlimitedAvailable.label": "Unlimited",
  "product.form.variant.stock.quantity.heading": "Quantity",
  "product.form.variant.availability.placeholder": "0",
  "product.form.variant.availability.label": "Available",
  "product.form.deleteText": "Delete",
  "product.form.submitText": "Save",
  "product.form.save.draft": "Save as Draft",
  "product.form.review.submission": "Submit for review",
  "product.form.cancelText": "Cancel",
  "product.form.delete.title": "",
  "product.form.delete.message":
    "Are you sure you want to delete this product?",
  "product.review.submission.successTitle": "Successful!",
  "product.review.submission.message":
    "Your changes have been successfully submitted for approval. Please await further review.",
  "product.draft.submission.message": "Saved as Draft",
  "product.review.submission.okText": "Ok",
  "product.form.delete.cancelText": "Cancel",
  "product.form.delete.confirmText": "Confirm",
  "product.form.variant.delete.title": "",
  "product.form.variant.delete.message":
    "Are you sure you want to remove this variant?",
  "product.form.all.variants.delete.message":
    "Are you sure you want to remove all variants?",
  "product.form.variant.delete.cancelText": "Cancel",
  "product.form.variant.delete.confirmText": "Confirm",
  "product.form.stores.fetchError":
    "Failed to load some stores. Click here to try again.",
  "product.form.variant.rack.heading": "Rack",
  "product.form.variant.aisle.heading": "Aisle",
  "product.form.variant.shelf.heading": "Shelf",
  "product.form.variant.position.placeholder": "Position",
  "product.form.variant.rack.placeholder": "Rack",
  "product.form.variant.aisle.placeholder": "Aisle",
  "product.form.variant.shelf.placeholder": "Shelf",
  "product.form.soldByWeight": "Sold by weight",
  "product.description.placeholder": "Describe yout product here",
  "recuring.product.label": "Recurring Product",
  "product.short.description.placeholder": "Short description of product",
  "save.text": "Save",
  "product.form.soldByWeight.enable": "Enable",
  "product.form.handlingDays.placeholder": "Enter pre-order handling days",
  "product.form.handlingDays.label": "pre-order handling days",
  "product.form.bulkThreshold.placeholder": "Enter bulk order threshold",
  "product.form.bulkThreshold.label": "bulk order threshold",
  "product.form.soldByWeight.pattern": "Incorrect name pattern",
  "product.form.packageType.pattern": "Incorrect type pattern",
  "product.form.variant.pattern":
    "Enter name in the format 'number space kg/g/gm' ",
  "product.form.brand.addButton": "Add new brand",
  "product.form.enter": "Enter",
  "product.form.lowStock": "Low Stock Alert Quantity",
  "product.form.lowStock.placeholder": "Enter Low Stock",
  "product.form.select": "Select",
  "product.form.details.header": "Product Details",
  "product.form.other.details.heading": "Other Details",
  "product.form.seller.price": "Seller Price",
  "product.form.profit.margin": "Profit Margin",
  "product.form.suggested.retail.price.header": "Suggested Retail Price",
  "product.dealer.price.header": "Dealer Price",
  "product.min.profit.margin.header": "Minimum Profit Margin",
  "product.min.profit.margin.placeholder": "Enter Minimum Profit Margin",
  "product.form.variant.price.header": "Price",
  "product.form.profit.margin.percentage": "Percentage",
  "product.short.description.label": "short description",
  "product.form.variant.tax.header": "Tax",
  "product.form.variant.location.header": "Location",
  "product.form.variant.stock.header": "Stock",
  "product.form.imageUpload.uploading": "Uploading...",
  "product.form.imageUploade.error": "Error in uploading",
  "product.form.secondarycategory.heading": "Secondary categories",
  "product.form.secondarycategory.placeholder": "Enter secondary categories",
  "product.form.discount.error": "Discount cannot be greater than price",
  "product.property.enable.text": "Enable Product Properties",
  "product.property.add.text": "Add Property",
  "product.property.add.placeholder": "Enter the Property Name",
  "product.property.add.success.text": "Property Name Added Successfully",
  "product.category.placeholder": "Search or add categories",
  "product.form.seller.price.heading": "Seller Pricing",

  "tag.heading": "Tags",
  "tag.add.text": "Add Tag",
  "tag.edit.text": "Edit Tag",
  "tag.helpItems.message": "Your product tags will appear here",
  "tag.form.name.heading": "Name",
  "tag.form.name.placeholder": "Enter tag name",
  "tag.form.tagline.heading": "tagline",
  "tag.form.tagline.placeholder": "Enter Tag Line",
  "tag.form.submitText": "Submit",
  "tag.form.cancelText": "Cancel",
  "tag.header.image": "Image",
  "tag.form.image.heading": "Image",
  "tag.form.image.placeholder": "Click here to upload, or drag your image",
  "tag.header.tag": "Tag",
  "tag.header.productCount": "Product Count",
  "tag.header.actions": "Actions",
  "tag.action.edit": "Edit",
  "tag.action.delete": "Delete",
  "tag.action.linkToProduct": "Link to products",
  "tag.action.seeProducts": "See products",
  "tag.action.viewOnWebsite": "View on Website",
  "tag.filters.name.heading": "Name",
  "tag.filters.name.placeholder": "Search by Name",
  "tag.filters.submitText": "Search",
  "tag.action.linkToProduct.heading": "Add '{name}' tag",
  "tag.linkToProduct.product.heading": "Product",
  "tag.linkToProduct.product.placeholder": "Enter a product name",
  "tag.linkToProduct.submitText": "Submit",
  "tag.linkToProduct.product.action.remove": "Remove",
  "tag.linkToProduct.product.action.retry": "Retry",
  "tag.filter.clear": "Clear All ",
  "tag.import": "Import Tags",

  "substitutionGroup.heading": "Substitution Groups",
  "substitutionGroup.add.text": "Add group",
  "substitutionGroup.helpItems.message":
    "A substitution group includes products that are similar to each other and may act as replacements when required.",
  "substitutionGroup.header.name": "Name",
  "substitutionGroup.header.productCount": "Product count",
  "substitutionGroup.header.status": "Status",
  "substitutionGroup.header.actions": "Actions",
  "substitutionGroup.status.enabled": "Enabled",
  "substitutionGroup.status.disabled": "Disabled",
  "substitutionGroup.action.edit": "Edit",
  "substitutionGroup.action.delete": "Delete",
  "substitutionGroup.form.name.heading": "Name",
  "substitutionGroup.form.name.placeholder": "Name this substitution group",
  "substitutionGroup.form.percentageDeviation.heading": "Percentage Deviation",
  "substitutionGroup.form.percentageDeviation.placeholder":
    "Enter a value between 0 and 100",
  "substitutionGroup.form.product.heading": "Product",
  "substitutionGroup.form.product.placeholder": "Enter name of a product",
  "substitutionGroup.form.product.requiredMessage":
    "A substitution group must have at least one product",
  "substitutionGroup.form.deleteText": "Delete",
  "substitutionGroup.form.submitText": "Submit",
  "substitutionGroup.form.cancelText": "Cancel",
  "substitutionGroup.filters.name.heading": "Name",
  "substitutionGroup.filters.name.placeholder": "Search by name",
  "substitutionGroup.filters.status.heading": "Name",
  "substitutionGroup.filters.status.label.all": "All",
  "substitutionGroup.filters.status.label.enabled": "Enabled",
  "substitutionGroup.filters.status.label.disabled": "Disabled",
  "substitutionGroup.filters.submitText": "Search",
  "substitutionGroup.filters.clearFiltersText": "Clear All",
  "substitutionGroup.action.disable": "Disable",
  "substitutionGroup.action.enable": "Enable",
  "substitutionGroup.dialogs.disable.title": "Are you sure?",
  "substitutionGroup.dialogs.enable.title": "Are you sure?",
  "substitutionGroup.dialogs.disable.message":
    "This action will disable the substitution group",
  "substitutionGroup.dialogs.enable.message":
    "This action will enable the substitution group",
  "substitutionGroup.dialogs.confirmText": "Confirm",
  "substitutionGroup.dialogs.cancelText": "Cancel",

  "catalogue.dashboard.heading": "Dashboard",
  "catalogue.dashboard.helpItems.title": "From the blog",
  "catalogue.dashboard.helpItems.gp.title":
    "Good Product description saves money",
  "catalogue.dashboard.helpItems.gp.description":
    "Websites with helpful description get free visitors from search engines like Google.",
  "catalogue.dashboard.helpItems.uI.title":
    "Why uploading images are important?",
  "catalogue.dashboard.helpItems.uI.description":
    "Images improves your landing page appeal which directly correlates with better conversion. ",
  "catalogue.dashboard.helpItems.cm.title":
    "Defining a content marketing stategy",
  "catalogue.dashboard.helpItems.cm.description":
    "Content marketing is a form of marketing focused on creating, publishing and distributing content for a targeted audience online.",
  "catalogue.dashboard.productsWithoutImages":
    "{count, plural, one{Product} other{Products} } without images",
  "catalogue.dashboard.productsWithoutDescription":
    "{count, plural, one{Product} other{Products} } without description",
  "catalogue.dashboard.categories":
    "{count, plural, one{Category} other{Categories} }",
  "catalogue.dashboard.products":
    "{count, plural, one{Product} other{Products} }",
  "catalogue.dashboard.brands": "{count, plural, one{Brand} other{Brands} }",
  "themes.bc.name": "Collection name",
  "themes.bc.name.placeholder": "Enter collection name",
  "themes.bc.blog.placeholder": "Enter blogs to show",
  "catalogue.product.restriction.image": "Image",
  "catalogue.product.restriction.name": "Name",
  "catalogue.product.restriction.startDate": "Start Date",
  "catalogue.product.restriction.endDate": "End Date",
  "catalogue.product.restriction.max.purchaseable.qty":
    "Max Purchasable Quantity",
  "catalogue.product.restriction.actions": "Actions",
  "catalogue.product.restriction.bulk.upload": "Bulk Upload",
  "product.filters.permissableSeller.heading": "Permissable Sellers",
  "product.seller.selection.heading": "Select Seller",
  "product.filters.sellerName.heading": "Seller Name",
  "product.filters.permissableSeller.placeholder": "Search by Sellers",
  "product.form.sellingMargin": "Selling Price: ",
  "product.form.profitMargin": "Profit Margin: ",
  "product.form.minimumProfitMargin.error":
    "Profit margin should be equal to or greater than {value}%",
};

export default data;
