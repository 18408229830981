import { GO_HOST } from "../config/app";

const is_Domain_Scarlet = () => {
  if (GO_HOST.includes("tamimimarkets.com")) return true;
  return false;
};

const is_Domain_WSI = () => {
  if (GO_HOST.includes("wesellit.ph")) return true;
  return false;
};

export { is_Domain_Scarlet, is_Domain_WSI };
